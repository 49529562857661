import React from 'react';
export default class LinkLabel extends React.Component {
  render() {
    return (
      <div className={'label'}>
        <div className={'label-text'}>{this.props.label}:</div>
        <div className={'label-value'}>
          <a href={this.props.value ? this.props.value : ''}>{this.props.linkText}</a>
        </div>
      </div>
    );
  }
}
