import React from 'react';
import Paper from '@material-ui/core/Paper';
import Label from './Label';
import LinkLabel from './LinkLabel';
import ArrivalButton from './ArrivalButton';
import moment from 'moment';
export default class Service extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      service: null,
    };
  }
  componentDidMount() {
    const serviceId = window.location.search.split('=')[1];
    const url = `${process.env.REACT_APP_HELIOS_API_URL}/providers/drivers/service/${serviceId}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => (data.status ? this.setState({ service: data }) : this.setState({ service: null })))
      .catch((error) => console.log(error));
  }

  getLocationLatLng = (lat, lng) => {
    return `http://www.google.com/maps/place/${lat},${lng}`;
  };

  situationFields = () => (
    <div>
      <LinkLabel label="Localización" linkText={this.state.service.pinSituation} value={this.getLocationLatLng(this.state.service.start_lat, this.state.service.start_long)} />
    </div>
  );

  driverFields = (driver) => {
    return driver ? (
      <div style={{ padding: 5 }}>
        <Label label="Driver" bold={true} />
        <Label label="Nombre" value={driver.name} />
        <Label label="Teléfono" value={driver.phone} />
        <Label label="Email" value={driver.email} />
        {driver.address ? <Label label="Dirección" value={driver.address} /> : null}
      </div>
    ) : null;
  };

  towFields = () => (
    <div>
      <LinkLabel
        label="Dirección de situación"
        linkText={this.state.service.pinStart}
        value={this.getLocationLatLng(this.state.service.start_lat, this.state.service.start_long)}
      />
      <LinkLabel
        label="Dirección de entrega"
        linkText={this.state.service.pinFinish}
        value={this.getLocationLatLng(this.state.service.finish_lat, this.state.service.finish_long)}
      />
    </div>
  );

  notesField = (notes) => {
    const serviceNotes = notes.sort(function (a, b) {
      return new Date(a.date) - new Date(b.date);
    });

    return (
      <div>
        {serviceNotes.map((note, index) => (
          <Label key={index} label={`Nota ${index + 1}`} value={note.msg} />
        ))}
      </div>
    );
  };

  render() {
    moment.localeData('es', {
      months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
      monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
      weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
      weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
      weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),
    });
    moment.locale('es');

    let info = <div></div>;
    if (this.state.service !== null) {
      const driver = this.state.service.driver || null;
      const isTow = this.state.service.finish_lat && this.state.service.finish_long;

      info = (
        <div>
          <div className="mb-25">
            <Label label="Compañia" value={this.state.service.account} />
            <Label label="PO" value={this.state.service.serviceNumber} />
            <Label label="Nombre" value={this.state.service.firstname + ' ' + this.state.service.lastname} />
            <Label hidden label="Teléfono" value={this.state.service.phone} />
            <Label label="Situación" value={this.state.service.situation} />
            <Label label="Marca" value={this.state.service.make} />
            <Label label="Modelo" value={this.state.service.model} />
            <Label label="Color" value={this.state.service.color} />
            <Label label="Año" value={this.state.service.year} />
            <Label label="Placa" value={this.state.service.plate} />
            <Label label="VIN" value={this.state.service.vin} />
            {this.state.service.schedule ? (
              <div>
                <Label label="Fecha" value={moment(this.state.service.schedule).format('D MMMM Y')} />
                <Label label="Hora" value={moment(this.state.service.schedule).format('hh:mm A')} />
              </div>
            ) : null}

            {isTow ? this.towFields() : this.situationFields()}
            {this.state.service.notes?.length ? this.notesField(this.state.service.notes) : null}
            {this.driverFields(driver)}
          </div>
          <ArrivalButton arrivalLink={this.state.service.arrival_link} finishLink={this.state.service.finish_link} status={this.state.service.tripStatus} />
        </div>
      );
    } else {
      info = (
        <div className="container">
          <div className="pin"></div>
          <h3>Servicio no encontrado.</h3>
        </div>
      );
    }
    return (
      <Paper className={'service'}>
        <h2>Información del servicio:</h2>
        {info}
      </Paper>
    );
  }
}
