import React from 'react';
import MButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class Service extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      status: this.props.status,
      dialogText: 'Tiempo de llegada actualizado.',
    };
    this.sendArrivalRequest = this.sendArrivalRequest.bind(this);
    this.sendFinishedRequest = this.sendFinishedRequest.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  sendArrivalRequest() {
    this.handleClickOpen();
    fetch(this.props.arrivalLink)
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          this.handleClickOpen();
          this.setState({
            status: 'arrived',
            dialogText: 'Tiempo de llegada actualizado.',
          });
        }
      })
      .catch((error) => console.log(error));
  }

  sendFinishedRequest() {
    this.handleClickOpen();
    fetch(this.props.finishLink)
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          this.handleClickOpen();
          this.setState({
            status: 'finished',
            dialogText: 'Tiempo de finalizar actualizado.',
          });
        }
      })
      .catch((error) => console.log(error));
  }

  render() {
    let link = this.state.status === 'arrived' || this.state.status === 'finished' ? this.sendFinishedRequest : this.sendArrivalRequest;
    let text = this.state.status === 'arrived' || this.state.status === 'finished' ? '¿Terminó?' : '¿Arribó?';
    return (
      <div className={'arrival-button'}>
        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogContent>
            <DialogTitle id="alert-dialog-title">{'Notificación'}</DialogTitle>

            <DialogContentText id="alert-dialog-description">{this.state.dialogText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <MButton onClick={this.handleClose} color="primary">
              Close
            </MButton>
          </DialogActions>
        </Dialog>

        <MButton variant={'raised'} color="primary" fullWidth={true} size={'large'} onClick={link}>
          {text}
        </MButton>
      </div>
    );
  }
}
