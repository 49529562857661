import React, { Component } from 'react';
import Service from './components/Service';
import logo from './assets/ic_logo.svg';
import './App.css';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ff4c00',
    },
  },
});

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <div className="App">
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
          </header>
          <Service />
        </div>
      </MuiThemeProvider>
    );
  }
}

export default App;
