import React from 'react';

export default class Label extends React.Component {
  render() {
    if (this.props.hidden) {
      return null;
    }
    const boldStyle = { fontWeight: 'bold' };
    let labelStyle = {};
    labelStyle = this.props.bold ? { ...labelStyle, ...boldStyle } : labelStyle;

    return (
      <div className={'label'}>
        <div className={'label-text'} style={labelStyle}>
          {this.props.label}:
        </div>
        <div className={'label-value'}>{this.props.value ? this.props.value : ''}</div>
      </div>
    );
  }
}
